<template>
  <div v-frag>
    <v-row dense no-gutters>
      <login-left/>
      <new-password-right/>
      <v-col cols='12' lg='1' md='1' sm='1' xl='1' xs='12'></v-col>
    </v-row>
  </div>
</template>
<script>
import LoginLeft from './components/LoginLeft'
import NewPasswordRight from './components/NewPasswordRight'

export default {
  name: 'NewPassword',
  components: {
    LoginLeft,
    NewPasswordRight
  },
  data: () => ({})
}
</script>
