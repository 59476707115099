<template>
  <v-col cols='12' lg='6' md='6' sm='6' xl='6' xs='12'>
    <v-card color='white' elevation='0' height='100%' width='100%'>
      <v-container class='pl-15 pr-15' fill-height>
        <v-row
          align='center'
          align-content='center'
          class='text-center'
          justify='center'
        >
          <v-col cols='12'>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              New Password
            </div>
          </v-col>

          <v-col cols='8'>
            <div class='subtitle-1 grey--text text--darken-2'>
              Set your new password to be able to access the MCBA Platform.
            </div>
          </v-col>
          <v-col class='mt-3' cols='8'>
            <v-divider></v-divider>
          </v-col>
          <v-col cols='8' dense>
            <v-form ref='form' v-model='valid' lazy-validation>
              <div
                class='
                  text-caption
                  grey--text
                  text--darken-2 text-start
                  mb-2
                  font-weight-medium
                '
              >
                New Password*
              </div>
              <v-text-field
                v-model='password'
                :rules='passwordRules'
                :type="show ? 'text' : 'password'"
                class='rounded-lg'
                color='primary'
                hide-details='auto'
                outlined
                required
                @click:append='show = !show'
              >
                <template v-slot:append>
                  <v-btn
                    v-if='show'
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show = !show'
                  >
                    hide
                  </v-btn>
                  <v-btn
                    v-else
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show = !show'
                  >
                    show
                  </v-btn>
                </template>
              </v-text-field>

              <div
                class='
                  text-caption
                  grey--text
                  text--darken-2 text-start
                  mb-2
                  font-weight-medium
                '
              >
                Repeat Password*
              </div>
              <v-text-field
                v-model='confirm'
                :rules='[
                  confirmRules[0],
                  confirmRules[1],
                  passwordConfirmationRule,
                ]'
                :type="show2 ? 'text' : 'password'"
                class='rounded-lg'
                color='primary'
                hide-details='auto'
                outlined
                required
                @click:append='show2 = !show2'
              >
                <template v-slot:append>
                  <v-btn
                    v-if='show2'
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show2 = !show2'
                  >
                    hide
                  </v-btn>
                  <v-btn
                    v-else
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show2 = !show2'
                  >
                    show
                  </v-btn>
                </template>
              </v-text-field>
              <v-checkbox
                v-model='checkbox'
                :rules='checkboxRules'
                label='I agree with the Terms of Services and agree to be an associative member of MCBA'
              >
                <template v-slot:label>
                  <div>
                    I accept my complimentary associate membership in MCBA and agree to the MCBA
                    <a :href='url' target='_blank' @click.stop
                    >Terms of Service</a
                    >.
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-col>

          <v-col class='mt-8' cols='8'>
            <v-btn
              :disabled='loading'
              :loading='loading'
              block
              class='white--text body-1 text-capitalize'
              color='primary'
              elevation='0'
              x-large
              @click='login'
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>
<script>
import home from '../../../services/home'

export default {
  mounted() {
    this.initialLoad()
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.confirm || 'Password must match'
    }
  },
  data: () => ({
    loading: false,
    show: false,
    show2: false,
    valid: true,
    password: '',
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        (v && v.length >= 8) || 'Password must be longer than 8 characters'
    ],
    confirm: '',
    confirmRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        (v && v.length >= 8) || 'Password must be longer than 8 characters'
    ],
    checkboxRules: [(v) => !!v || 'The field is required'],
    checkbox: false,
    url: 'https://mcbadfw.org/terms-of-use/'
  }),
  methods: {
    async initialLoad() {
      this.$store.dispatch('token', '')
      this.$store.dispatch('user', {
        name: '',
        rol: '',
        image: ''
      })
    },
    async login() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          await home.newPassword({
            password: this.password,
            confirm: this.confirm,
            agreements: this.checkbox,
            id: this.$route.params.id
          })
          setTimeout(() => {
            this.$router.replace({name: 'ConfirmationAccount'})
            this.loading = false
          }, 1000)
        } catch (err) {
          // empty
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  }
}
</script>
